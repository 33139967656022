div[role="application"].tox.tox-tinymce {
    border-radius: 5px;
    border: 1px solid var(--text-b2bfamily-black-color);
}

editor .tox .tox-editor-header {
    border-bottom: 1px solid var(--text-b2bfamily-black-color);
}

editor .tox .tox-toolbar__group {
    padding: 0;
    height: 32px;
    margin: 3px;
    border-radius: 5px;
    border: 1px solid var(--bg-color);
}

editor .tox-editor-header .tox-toolbar-overlord .tox-toolbar[role="group"]:first-child {
    margin-bottom: -3px;
}

editor .tox-toolbar[role="group"] {
    background: var(--bg-color);
}

editor .tox .tox-tbtn svg {
    fill: var(--color-text) !important;
}

editor .tox-tbtn--enabled, .tox-tbtn--enabled:hover {
    background: var(--bg-editor-tox-color) !important;
}

editor .tox .tox-tbtn {
    margin: 0;
    width: 24px;
    height: 100%;
    border-right: 1px solid var(--bg-color);
    border-radius: 5px;
    color: var(--color-text) !important;
    background: var(--bg-color);
}

editor .tox .tox-split-button {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

editor .tox .tox-split-button:hover {
    cursor: pointer;
    box-shadow: none;
}

editor .tox .tox-split-button__chevron {
    width: 12px;
}

editor .tox [aria-label="Шрифты"] {
    width: 105px;
}

editor .tox [aria-label="Размер шрифта"] {
    width: 54px;
}


editor .tox .tox-tbtn:hover {
    cursor: pointer;
    background: var(--bg-editor-tox-color) !important;
    border-right: 1px solid transparent !important;
    border-radius: 5px;
    box-shadow: none;
    color: var(--color-text);
}

editor .tox .tox-tbtn:focus {
    background: var(--bg-editor-tox-color) !important;
}

editor .tox .tox-tbtn[aria-label="Таблица"] {
    width: 35px;
}

editor .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
    border-right: 1px solid var(--bg-color);
}
