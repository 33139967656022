.tinymce-editor-component .tox .tox-collection--list .tox-collection__item {
    padding: 5px 15px 5px 15px;
    height: 26px;
}

.tinymce-editor-component .tox .tox-menu {
    background-color: var(--zoom-bg-color);
    border: 1px solid var(--editor-dropdown-border-color) !important;
    border-radius: 5px;
    max-height: 196px;
    overflow: auto;
    padding: 4px 0px 0px;
    box-shadow: none !important;
}

.tinymce-editor-component .tox .tox-collection__item {
    color: var(--editor-select-text-color) !important;
    font-style: normal;
    border-radius: 0px !important;
    font-weight: 400;
    font-size: 13px !important;
    line-height: 16px !important;
    box-sizing: border-box;
    display: block;
}

.tinymce-editor-component .tox .tox-collection--list .tox-collection__item {
    height: 27px;
    padding: 0 14px;
    width: 100%;
    min-width: 47px;
    display: flex;
}

.tinymce-editor-component .tox .tox-collection--toolbar-lg .tox-collection__item {
    height: 27px;
    padding: 0 14px;
    width: 100%;
    min-width: 48px;
    display: flex;
}

.tinymce-editor-component .tox .tox-collection--toolbar-lg .tox-collection__group:nth-child(1) .tox-collection__item:nth-child(1) {
    margin-top: 7px;
}

.tinymce-editor-component .tox .tox-collection--toolbar-lg .tox-collection__group:nth-child(2) .tox-collection__item:nth-child(1),
.tinymce-editor-component .tox .tox-collection--toolbar-lg .tox-collection__group:nth-child(1) .tox-collection__item:nth-child(3) {
    margin-bottom: 7px;
}

.tinymce-editor-component .tox .tox-collection--list .tox-collection__item:nth-child(1) {
    order: 1;
    margin-top: 3px;
}
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item:nth-child(2) {
    order: 2;
}
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item:nth-child(3) {
    order: 3;
}
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item:nth-child(4) {
    order: 4;
}

.tinymce-editor-component .tox .tox-collection__item-icon svg {
    fill: currentColor;
    width: 19px;
    height: 19px;
}

.tinymce-editor-component .tox .tox-collection__item-checkmark,
.tinymce-editor-component .tox .tox-collection__item:has(.tox-collection__item-icon) .tox-collection__item-label {
    display: none !important;
}

.tinymce-editor-component .tox .tox-collection--list .tox-collection__group {
    display: flex;
    flex-wrap: wrap;
    width: min-content;
    max-height: 181px !important;
    overflow: hidden auto;
}

.tinymce-editor-component .tox ::-webkit-scrollbar {
    width: 1px;
    height: 0px;
}

.tinymce-editor-component .tox ::-webkit-scrollbar-thumb {
    background-color: var(--bg-gray-color);
}

.tinymce-editor-component .tox ::-webkit-scrollbar-track {
    background: transparent;
}

.tinymce-editor-component .tox .tox-swatch {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin: 5px;
    padding: 0;
}

.tinymce-editor-component .tox .tox-swatch:focus, .tox .tox-swatch:hover {
    box-shadow: 0 0 0 1px rgb(127 127 127 / 30%) inset;
    transform: scale(1) !important;
    width: 27px;
    height: 27px;
    margin: 4px;
}

.tinymce-editor-component .tox .tox-swatches .tox-swatches__row:last-child > div:first-child {
    width: calc(100% + 8px);
    border-top: 1px solid #BFBFBF;
    border-radius: 0;
    padding-top: 3px;
    margin: 0 -4px;
    order: 10;
}

.tinymce-editor-component .tox .tox-swatches .tox-swatches__row:last-child > div:first-child:focus,
.tinymce-editor-component .tox .tox-swatches .tox-swatches__row:last-child > div:first-child:hover {
    box-shadow: none;
    transform: scale(1) !important;
    height: 25px;
    cursor: pointer;
}

.tinymce-editor-component .tox .tox-swatches .tox-swatches__row:last-child > div:first-child svg {
    display: none;
}

.tinymce-editor-component .tox .tox-swatches .tox-swatches__row:last-child > div:first-child::after {
    content: 'Сбросить';
    color: var(--tinymce-select-menu-text-color);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
}

.tinymce-editor-component .tox .tox-swatch[title="transparent"] {
    visibility: hidden;
}

.tinymce-editor-component .tox .tox-collection__item-checkmark .editor-sensei-stroke-color,
.tinymce-editor-component .tox .tox-collection__item-icon .editor-sensei-stroke-color {
    stroke: var(--zoom-icons-color);
}

.tinymce-editor-component .tox .tox-collection__item-checkmark .editor-sensei-fill-color,
.tinymce-editor-component .tox .tox-collection__item-icon .editor-sensei-fill-color {
    fill: var(--zoom-icons-color);
}

.tinymce-editor-component .tox .tox-collection__item-icon {
    align-items: center;
    display: flex;
    height: 24px;
    justify-content: center;
    width: auto;
}

.tinymce-editor-component .tox .tox-menu.tox-collection.tox-collection--list {
    max-height: 189px !important;
    min-width: 50px !important;
    overflow: hidden !important;
}

.tinymce-editor-component-full-screen constructor-top-menu {
    z-index: 0 !important;
}

.tinymce-editor-component .tox .tox-swatches__row {
    padding: 0 4px;
}

.tinymce-editor-component .tox .tox-swatches__row:last-child {
    margin-top: 4px;
    margin-bottom: 5px;
    flex-wrap: wrap;
}

.tinymce-editor-component .tox .tox-swatch[data-mce-color="#FFFFFF"] {
    box-shadow: 0 0 0 1px rgba(127,127,127,.3) inset;
}

.tinymce-editor-component .tox .tox-collection__item-label {
    color: currentColor;
    display: inline-block;
    flex: 1;
    -ms-flex-preferred-size: auto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: none;
    word-break: break-all;
}

.tinymce-editor-component .tox:not([dir=rtl]) .tox-collection--list .tox-collection__item>.tox-collection__item-label:first-child {
    margin-left: -1px;
}

.tinymce-editor-component .tox .tox-collection--toolbar .tox-collection__group {
    flex-wrap: wrap;
    width: min-content;
    padding: 0;
}

.tinymce-editor-component .tox .tox-collection--toolbar-lg:has(.tox-collection__group:nth-child(2)) .tox-collection__group:nth-child(1) .tox-collection__item:nth-child(3),
.tinymce-editor-component .tox .tox-collection--toolbar-lg .tox-collection__group:nth-child(2) .tox-collection__item:nth-child(2),
.tinymce-editor-component .tox .tox-collection--toolbar-lg .tox-collection__group:nth-child(2) .tox-collection__item:nth-child(3) {
    display: none;
}

.tinymce-editor-component .tox .tox-collection--toolbar-lg .tox-collection__item-icon {
    height: auto;
    width: auto;
}


.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Andale Mono"], .tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Arial"],
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Arial Black"], .tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Book Antiqua"],
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Comic Sans MS"], .tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Courier New"],
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Georgia"], .tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Helvetica"],
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Impact"], .tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Symbol"],
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Tahoma"], .tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Terminal"],
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Times New Roman"], .tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Trebuchet MS"],
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Verdana"], .tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Webdings"],
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Wingdings"] {
    min-width: 224px;
}

.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Текущее окно"], .tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="Новое окно"] {
    min-width: 348px;
}

.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="8px"], .tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="9px"],
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="10px"], .tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="11px"],
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="12px"], .tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="14px"],
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="16px"], .tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="18px"],
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="24px"], .tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="30px"],
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="36px"], .tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="48px"],
.tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="64px"], .tinymce-editor-component .tox .tox-collection--list .tox-collection__item[title="72px"] {
    min-width: 87px;
}

.tinymce-editor-component .tox .tox-dialog__popups, .tinymce-editor-component .tox .tox-dialog__popups * {
    min-width: 348px;
    max-width: 348px;
    /* width: 348px; */
}

.tinymce-editor-component .tox .tox-menu.tox-collection.tox-collection--toolbar {
    padding: 0px !important;
}

.tinymce-editor-component .tox .tox-dialog {
    background-color: var(--bg-white-color);
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    border-width: 0px;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    max-width: 350px;
    overflow: hidden;
    position: relative;
    padding: 25px 25px 25px 25px;
    width: 95vw;
    z-index: 2;
}

.tinymce-editor-component .tox .tox-dialog__header {
    background-color: var(--bg-white-color);
    color: var(--color-text);
    padding: 0;

    align-items: center;

    border-bottom: none;

    display: flex;
    font-size: 16px;
    justify-content: space-between;

    position: relative;
}

.tinymce-editor-component .tox .tox-dialog__title {
    font-family: Proxima_Nova_bold;
    font-size: 17px;
    line-height: 21px;
    margin: 0;
    text-transform: none;
}

.tinymce-editor-component .tox .tox-dialog .tox-label, .tinymce-editor-component .tox .tox-dialog .tox-toolbar-label {
    color: var(--color-text);
    display: block;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    padding: 0;
    text-transform: none;
    white-space: nowrap;
    margin-bottom: 5px;
}

.tinymce-editor-component .tox .tox-listbox__select-label {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin: 0;
}

.tinymce-editor-component .tox .tox-collection__item.tox-collection__item--enabled > .tox-collection__item-label {
    font-family: Proxima_Nova_bold;
}

.tinymce-editor-component .tox .tox-dialog__body-content {
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column;
    -ms-flex-preferred-size: auto;
    max-height: 650px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 5px 0px 0px;
}

.tinymce-editor-component .tox input, .tinymce-editor-component .tox input:focus, .tinymce-editor-component .tox .tox-listboxfield .tox-listbox--select,
.tinymce-editor-component .tox .tox-listboxfield .tox-listbox--select:focus {
    background: var(--zoom-bg-color);
    border: 1px solid var(--black-text-if-modal-color);
    color: var(--zoom-icons-color);
    border-radius: 5px;
    height: 36px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    padding-left: 14px;
}

.tinymce-editor-component .tox .tox-textarea, .tinymce-editor-component .tox .tox-textarea:focus {
    background: var(--zoom-bg-color);
    border: 1px solid var(--black-text-if-modal-color);
    color: var(--zoom-icons-color);
    border-radius: 5px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    padding: 9px 14px;
}

.tinymce-editor-component .tox .tox-listbox__select-chevron {
    display: flex;
    justify-content: flex-start;
    width: 16px;
    margin-top: -2px;
    align-items: flex-start;
}

.tinymce-editor-component .tox .tox-listbox__select-chevron::after {
    content: var(--tinymce-chevron-icon);
}

.tinymce-editor-component .tox .tox-listbox__select-chevron svg, .tinymce-editor-component .tox .tox-dialog__header .tox-button {
    display: none;
}

.tinymce-editor-component .tox .tox-form__group {
    margin-bottom: 10px;
}

.tinymce-editor-component .tox .tox-dialog__footer {
    align-items: center;
    background-color: var(--bg-white-color);
    border-top: 0;
    display: flex;
    justify-content: space-between;
    padding: 0;
    flex-direction: row-reverse;
    margin-top: 5px;
}

.tinymce-editor-component .tox .tox-dialog__footer .tox-dialog__footer-end button[title="Закрыть"] {
    order: 1;
    padding: 10px 15px;
    width: 90px;
    height: 36px;
    border: none;
    background-color: var(--bg-white-small-color);
    font-family: Proxima_Nova_bold;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: var(--text-b2bfamily-black-color);
    margin-left: 1px !important;
}

.tinymce-editor-component .tox .tox-dialog__footer .tox-dialog__footer-end button[title="Сохранить"] {
    padding: 9px 15px 10px 14px !important;
    width: 96px;
    height: 36px;
    border: 1px solid var(--invalid-color);
    border-radius: 10px;
    background-color: var(--bg-white-color);
    font-family: Proxima_Nova_bold;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: var(--invalid-color);
}

.tinymce-editor-component .tox .tox-dialog__footer .tox-dialog__footer-end>* {
    margin-left: 0 !important;
}

.tinymce-editor-component .tox-form__controls-h-stack > * {
    width: 100%;
}

.tinymce-editor-component .tox-form__controls-h-stack .tox-form__group:nth-child(3n+3) {
    display: none;
}

.tinymce-editor-component .tox .tox-form__controls-h-stack .tox-form__group {
    margin-bottom: 0px;
}

.tinymce-editor-component .tox .tox-form__controls-h-stack>:not(:first-child) {
    margin-left: 10px !important;
}

.tinymce-editor-component .tox .tox-dialog__body {
    color: var(--zoom-icons-color);
    display: flex;
    flex: 1;
    -ms-flex-preferred-size: auto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    min-width: 0;
    text-align: left;
    text-transform: none;
    flex-direction: column;
    margin-top: 10px;
}

.tinymce-editor-component .tox .tox-dialog__body-nav-item:focus {
    background-color: transparent;
}

.tinymce-editor-component .tox .tox-dialog__body-nav {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin-bottom: 10px;
}

.tinymce-editor-component .tox .tox-dialog__body-nav-item {
    border-bottom: 1px solid var(--94-color);
    font-family: Proxima_Nova_bold;
    color: var(--94-color);
    font-size: 13px;
    line-height: 16px;
    display: inline-block;
    padding: 10px 20px 9px;
    margin-bottom: 0;
    text-decoration: none;
    white-space: nowrap;
}

.tinymce-editor-component .tox .tox-dialog__body-nav-item--active {
    border-bottom: 1px solid var(--invalid-color) !important;
    color: var(--invalid-color) !important;
}

.tinymce-editor-component .tox .tox-dialog--width-lg {
    height: 650px;
    max-width: 1200px !important;
}

.tinymce-editor-component .tox .tox-dialog-wrap__backdrop {
    background-color: var(--bg-default-overlay-color);
}


